import { EMAIL, PHONE } from '../constants'
import { FaEnvelope, FaPhone } from 'react-icons/fa'

export const Contact = () => {
  return (
    <div className="contact">
      <div>
        <FaPhone />
        {PHONE}
      </div>
      <div>
        <FaEnvelope />
        {EMAIL}
      </div>
    </div>
  )
}
