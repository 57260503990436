import { CLOSED } from '../constants'

export const OpeningHours = () => {
  return (
    <div className="opening-hours">
      {CLOSED && <h3>{CLOSED}</h3>}
      <table>
        <thead>
          <tr>
            <th></th>
            <th>Listopad - Březen</th>
            <th>Duben - Říjen</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <span className="short">PO</span>
              <span className="long">Pondělí</span>
            </td>
            <td>
              <div className="time">
                <div>9:00 - 12:00</div> <div>14:00 - 15:30</div>
              </div>
            </td>
            <td>
              <div className="time">
                <div>8:00 - 12:00</div> <div>13:30 - 17:00</div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <span className="short">ÚT</span>
              <span className="long">Úterý</span>
            </td>
            <td>
              <div className="time">
                <div>9:00 - 12:00</div> <div>14:00 - 15:30</div>
              </div>
            </td>
            <td>
              <div className="time">
                <div>8:00 - 12:00</div> <div>13:30 - 17:00</div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <span className="short">ST</span>
              <span className="long">Středa</span>
            </td>
            <td>
              <div className="time">
                <div className="invisible">9:00 - 12:00</div>
                <div>14:00 - 15:30</div>
              </div>
            </td>
            <td>
              <div className="time">
                <div>8:00 - 12:00</div> <div>13:30 - 17:00</div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <span className="short">ČT</span>
              <span className="long">Čtvrtek</span>
            </td>
            <td>
              <div className="time">
                <div>9:00 - 12:00</div> <div>14:00 - 15:30</div>
              </div>
            </td>
            <td>
              <div className="time">
                <div>8:00 - 12:00</div> <div>13:30 - 17:00</div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <span className="short">PÁ</span>
              <span className="long">Pátek</span>
            </td>
            <td>
              <div className="time">
                <div>9:00 - 12:00</div> <div>14:00 - 15:30</div>
              </div>
            </td>
            <td>
              <div className="time">
                <div>8:00 - 12:00</div> <div>13:30 - 17:00</div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <span className="short">SO</span>
              <span className="long">Sobota</span>
            </td>
            <td>Zavřeno</td>
            <td>
              <div className="small">Po předchozí telefonické</div>
              <div className="small">domluvě od pondělí do pátku</div>
              <div className="padding-top">9:00 - 11:00</div>
            </td>
          </tr>
          <tr>
            <td>
              <span className="short">NE</span>
              <span className="long">Neděle</span>
            </td>
            <td>Zavřeno</td>
            <td>Zavřeno</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}
