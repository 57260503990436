export const Services = () => {
  return (
    <div className="services">
      {[
        {
          title: 'Doprava',
          text: 'Po vzájemné dohodě se zákazníkem, zajišťujeme dopravu zakoupeného zboží na místo určení malou dodávkou, nebo velkým nákladním autem.  Cena dohodou, dle velikosti, množství materiálu a vzdálenosti.',
        },
        {
          title: 'Tesařské a stolařské práce',
          text: 'Ve spolupráci s osvědčenými tesaři a stolaři zajišťujeme montáž a stavbu pergol, autostání, altánů, chatek, plotů, přístřešků a dřevěných teras a další tesařské, stolařské a podlahářské práce.',
        },
        {
          title: 'Hoblování',
          text: 'Hoblování dřevařských profilů pro montáž pergol, autostání, altánů, chatek, plotů, přístřešků a dřevěných teras.',
        },
        {
          title: 'Nátěr dřevařských výrobků a polotovarů',
          text: 'Na vyžádání zákazníka provádíme nátěr dřevařských výrobků a polotovarů barvami, speciálními laky a vosky na dřevo z naší nabídky.',
        },
        {
          title: 'Zakázková výroba',
          text: 'Na základě požadavků zákazníka, po předložení výkresové dokumentace, vyrobíme a dodáme polotovary schodnic, podschodnic a zábradlí. Taktéž provedeme montáž celého schodiště. Na základě výkresové dokumentace dodáme atypické dřevařské výrobky, jako spárovka, lepené hranoly, plotové dílce, madla, prahy, ručně štípaný šindel a jiné. V případě potřeby provedeme zaměření zdarma.',
        },
      ].map(({ title, text }) => (
        <div key={title}>
          <h4>{title}</h4>
          <p>{text}</p>
        </div>
      ))}
    </div>
  )
}
