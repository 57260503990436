import { FaAddressCard } from 'react-icons/fa'

export const Address = () => {
  return (
    <div className="address">
      <FaAddressCard />
      <div>
        <p>Ing. Bohumír Čechák</p>
        <p>Hanácká 77/10a</p>
        <p>Přerov</p>
        <p>751 24</p>
      </div>
    </div>
  )
}
