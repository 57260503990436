import { FaArrowDown } from 'react-icons/fa'
import { Contact } from './Contact'
import styled, { keyframes } from 'styled-components'
import { bounce } from 'react-animations'

const BounceIcon = styled(FaArrowDown)`
  animation: 1s ${keyframes`${bounce}`} infinite;
`

export const Home = () => {
  const onScroll = () => {
    window.scrollTo({
      left: 0,
      top: window.innerHeight,
      behavior: 'smooth',
    })
  }

  return (
    <div className="home">
      <Contact />
      <h1>Dřevoprodej Tempo</h1>
      <BounceIcon className="arrow" onClick={onScroll} />
    </div>
  )
}
