import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api'

const position = {
  lat: 49.474865,
  lng: 17.445269,
}

export const MapComponent = () => {
  const { isLoaded } = useJsApiLoader({
    id: 'tempo-387313',
    googleMapsApiKey: 'AIzaSyAxSwYQkRsHbMRvf__I09PzyaIOyw5lqJo',
  })

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={{
        width: '100%',
        height: '420px',
      }}
      center={position}
      zoom={13}
    >
      <Marker {...{ position }} />
    </GoogleMap>
  ) : (
    <></>
  )
}
