export const Goods = () => {
  return (
    <div className="goods">
      <div>
        <h2>Nabídka</h2>
        <div className="list">
          {createList([
            'Palubky obkladové',
            'Palubky podlahové',
            'OSB desky',
            'Stavební řezivo',
            'Hranoly',
            'Trámy střešní',
            'Kulatina stavební',
            'Terasový profil',
          ])}
          {createList([
            'Plotovky',
            'Nášlapy - Schodnice',
            'Soustružený profil',
            'Překližka',
            'Madla',
            'Prahy',
            'Dřevěné výrobky',
            'Barvy na dřevo',
          ])}
        </div>
      </div>
    </div>
  )
}

const createList = (items: string[]) => (
  <ul>
    {items.map((item) => (
      <li key={item}>{item}</li>
    ))}
  </ul>
)
