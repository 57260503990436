import { Address } from './components/Address'
import { Contact } from './components/Contact'
import { Footer } from './components/Footer'
import { Goods } from './components/Goods'
import { Home } from './components/Home'
import { Loader } from './components/Loader'
import { MapComponent } from './components/Map'
import { OpeningHours } from './components/OpeningHours'
import { Services } from './components/Services'
import CacheBuster from 'react-cache-buster'

const pjson = require('../package.json')

export const App = () => {
  return (
    <CacheBuster
      currentVersion={pjson.version}
      isEnabled={true}
      isVerboseMode={false}
      loadingComponent={<Loader />}
    >
      <div className="app">
        <Home />
        <div className="content">
          <OpeningHours />
          <MapComponent />
          <div className="contact-with-address">
            <Contact />
            <Address />
          </div>
          <hr />
          <Goods />
          <Services />
        </div>
        <Footer />
      </div>
    </CacheBuster>
  )
}
