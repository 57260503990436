export const Footer = () => {
  return (
    <div className="footer">
      <p>
        &copy; Copyright {new Date().getFullYear()}, Dřevoprodej Tempo
        &nbsp;&nbsp;&nbsp; | &nbsp;&nbsp;&nbsp; Photo by{' '}
        <a href="https://unsplash.com/@akb001?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
          Andrey Haimin
        </a>{' '}
        on{' '}
        <a href="https://unsplash.com/photos/q2Fyzn-KJOQ?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
          Unsplash
        </a>
      </p>
    </div>
  )
}
